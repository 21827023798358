import {
  Box,
  Button,
  ButtonProps,
  Text,
  Tooltip,
  useBreakpointValue,
} from '@chakra-ui/react'
import React, { ReactNode } from 'react'

const CollapsibleButton: React.FC<
  {
    icon: React.ComponentType
    label: string
    tooltip?: ReactNode
    iconSize?: string
  } & ButtonProps
> = ({ icon, label, tooltip, iconSize = '18px', ...btnProps }) => {
  const forceTooltip = tooltip !== undefined
  const isTooltipDisabled = useBreakpointValue({ base: false, lg: true })

  return (
    <Tooltip
      label={tooltip || label}
      aria-label={label}
      isDisabled={forceTooltip ? false : isTooltipDisabled}
    >
      <Button
        variant="ghost"
        size="sm"
        px={[0, null, null, 2]}
        fontSize="sm"
        {...btnProps}
      >
        <Box boxSize={`${iconSize}!important`} as={icon} />
        {label && (
          <Text ml={1} display={['none', null, null, 'block']}>
            {label}
          </Text>
        )}
      </Button>
    </Tooltip>
  )
}

export default CollapsibleButton
