import analytics from '@capturi/analytics'
import { useIsHovering } from '@capturi/react-utils'
import { DefaultColor, ShapeMark } from '@capturi/sharing'
import { Box, Tooltip, useTheme } from '@chakra-ui/react'
import { getColor } from '@chakra-ui/theme-tools'
import React, { useCallback, useEffect, useState } from 'react'

import { Event as AnalyticsEvent, log } from '../../events'
import { LeveledHit } from '../types'

type HitMarkProps = {
  hit: LeveledHit
  totalDuration: number
  tooltipPlacement: 'top' | 'bottom'
  tooltipContainerRef: React.RefObject<HTMLDivElement>
  onClick?: (hit: LeveledHit) => void
}

const HitMark: React.FC<HitMarkProps> = ({
  hit,
  totalDuration,
  tooltipPlacement,
  tooltipContainerRef,
  onClick,
}) => {
  const ref = React.createRef<HTMLDivElement>()
  const tooltipRef = React.createRef<HTMLDivElement>()
  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent): void => {
      e.stopPropagation()
      onClick?.(hit)
      analytics.event('conversationDetails_playerHit_playAudio')
    },
    [hit, onClick],
  )

  useEffect(() => {
    const stopPropagation = (ev: MouseEvent | TouchEvent): void => {
      ev.stopPropagation()
    }

    // Prevents useSlider from activating when clicking on the mark
    ref.current?.addEventListener('mousedown', stopPropagation)
    ref.current?.addEventListener('touchstart', stopPropagation)
    ref.current?.addEventListener('click', handleClick)
    const currentRef = ref.current // capture the ref for the cleanup function
    return () => {
      currentRef?.removeEventListener('mousedown', stopPropagation)
      currentRef?.removeEventListener('touchstart', stopPropagation)
      currentRef?.removeEventListener('click', handleClick)
    }
  }, [ref, handleClick])

  const isHovering = useIsHovering(ref)
  const [tooltipIsHovering, setTooltipIsHovering] = useState(false)
  const handleMouseEnter = useCallback(() => {
    setTooltipIsHovering(true)
  }, [])
  const handleMouseLeave = useCallback(() => {
    setTooltipIsHovering(false)
  }, [])
  const handleTooltipOpen = useCallback(() => {
    log(AnalyticsEvent.HitMarkTooltipOpened)
  }, [])

  const theme = useTheme()
  const handleGetColorValue = useCallback(
    (color: string) => {
      return getColor(theme, color)
    },
    [theme],
  )

  const level = hit.level ?? 0
  const translateYpx = level * (tooltipPlacement === 'top' ? -5 : 5)

  return (
    <Tooltip
      key={hit.id}
      label={hit.title}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={tooltipRef}
      placement={tooltipPlacement}
      isOpen={hit.tooltipIsOpen || isHovering}
      onOpen={handleTooltipOpen}
      background={hit.color ?? DefaultColor}
      variant={tooltipIsHovering ? 'hitmarkInFront' : 'hitmark'}
      onClick={handleClick}
      modifiers={[
        {
          name: 'preventOverflow',
          options: {
            mainAxis: false,
          },
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements: [],
          },
        },
        {
          name: 'offset',
          options: {
            offset: [0, tooltipPlacement === 'top' ? 7 : 0],
          },
        },
      ]}
      portalProps={{
        containerRef: tooltipContainerRef,
      }}
    >
      <Box
        left={`${(hit.timestamp / totalDuration) * 100}%`}
        transform={`translate(-50%, ${translateYpx}px)`}
        position="absolute"
        ref={ref}
        cursor="pointer"
        zIndex={0}
        _hover={{
          zIndex: 1,
        }}
      >
        <ShapeMark
          color={hit.color}
          shape={hit.shape}
          outline={hit.tooltipIsOpen || isHovering}
          isDimmed={hit.isDimmed && !isHovering}
          getColorValue={handleGetColorValue}
        />
      </Box>
    </Tooltip>
  )
}

export default HitMark
