import { useOrganization } from '@capturi/stores'
import { Avatar, Flex, HStack, Text } from '@chakra-ui/react'
import { select } from '@lingui/macro'
import React from 'react'

import { userRowHeightPx } from '../constants'

const CustomerAvatar: React.FC = () => {
  const { organizationType } = useOrganization()

  const customerText = select(organizationType, {
    public: 'Citizen',
    other: 'Customer',
  })

  return (
    <Flex align="center" h={userRowHeightPx} alignItems="flex-start">
      <HStack align="center">
        <Text display={['none', null, null, 'block']}>{customerText}</Text>
        <Avatar size="xs" title={customerText} aria-label={customerText} />
      </HStack>
    </Flex>
  )
}

export default CustomerAvatar
