import styled from '@capturi/ui-theme'
import React from 'react'

type Props = {
  value: number
}

const Bar = styled.div`
  position: absolute;
  height: 100%;
  width: 0px;
  background-color: ${(p): string => p.theme.colors.blackAlpha[300]};
  transition: 220ms linear;

  border-right: 3px solid;
  border-right-color: ${(p): string => p.theme.colors.whiteAlpha[800]};

  box-shadow: ${(p): string => p.theme.shadows.xs};

  border-top-left-radius: ${(p): string => p.theme.radii.sm};
  border-bottom-left-radius: ${(p): string => p.theme.radii.sm};
`

const SeekBar: React.FC<Props> = ({ value }) => {
  return <Bar style={{ width: `${value}%` }} />
}

export default SeekBar
