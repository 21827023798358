import { When } from '@capturi/api-conversations'
import { Box, Flex, Grid, GridProps } from '@chakra-ui/react'
import React, { useMemo } from 'react'

import { hitsLineHeightPx, talkLineHeightPx } from '../constants'
import { Hit, LeveledHit } from '../types'
import HitMark from './HitMark'
import SeekBar from './SeekBar'

const HitsLine: React.FC<{
  hits: Hit[]
  totalDuration: number
  tooltipPlacement: 'top' | 'bottom'
  tooltipContainerRef: React.RefObject<HTMLDivElement>
  onHitClick?: (hit: LeveledHit) => void
}> = ({
  hits,
  totalDuration,
  tooltipPlacement,
  tooltipContainerRef,
  onHitClick,
}) => {
  const leveledHitsOnSameTimestamp = useMemo(() => {
    return hits.reduce((acc, hit) => {
      const last = acc[acc.length - 1]
      if (last && last.timestamp === hit.timestamp) {
        acc.push({ ...hit, level: last.level + 1 })
      } else {
        acc.push({ ...hit, level: 0 })
      }
      return acc
    }, new Array<LeveledHit>())
  }, [hits])

  return (
    <Flex alignItems="center" w="100%" h="100%">
      {leveledHitsOnSameTimestamp.map((hit) => (
        <MemoizedHitMarkWrapper
          key={hit.id}
          hit={hit}
          totalDuration={totalDuration}
          tooltipPlacement={tooltipPlacement}
          tooltipContainerRef={tooltipContainerRef}
          onClick={onHitClick}
        />
      ))}
    </Flex>
  )
}

const HitMarkWrapper: React.FC<{
  hit: LeveledHit
  totalDuration: number
  tooltipPlacement: 'top' | 'bottom'
  tooltipContainerRef: React.RefObject<HTMLDivElement>
  onClick?: (hit: LeveledHit) => void
}> = ({
  hit,
  totalDuration,
  tooltipPlacement,
  tooltipContainerRef,
  onClick,
}) => {
  const handleClick = React.useCallback(() => {
    onClick?.(hit)
  }, [hit, onClick])
  return (
    <HitMark
      key={hit.id}
      hit={hit}
      totalDuration={totalDuration}
      tooltipPlacement={tooltipPlacement}
      tooltipContainerRef={tooltipContainerRef}
      onClick={handleClick}
    />
  )
}

const MemoizedHitMarkWrapper = React.memo(HitMarkWrapper)

export type HitsLocation = 'top' | 'bottom'

type TalkLineProps = {
  seekerValue: number
  duration: number
  data: When[]
  hits: Hit[]
  hitsLocation: HitsLocation
  hitTooltipContainerRef: React.RefObject<HTMLDivElement>
  onHitClick?: (hit: Hit) => void
} & GridProps

const TalkLine: React.FC<TalkLineProps> = ({
  data,
  hits,
  seekerValue,
  duration: totalDuration,
  hitsLocation,
  hitTooltipContainerRef,
  onHitClick,
  ...restProps
}) => {
  const scale = totalDuration / 100 || 1

  return (
    <Grid
      {...restProps}
      gridTemplateRows={
        hitsLocation === 'top'
          ? `${hitsLineHeightPx} ${talkLineHeightPx}`
          : `${talkLineHeightPx} ${hitsLineHeightPx}`
      }
      gridRowGap={hitsLocation === 'top' ? '0' : '1'}
    >
      {hitsLocation === 'top' && (
        <HitsLine
          hits={hits}
          totalDuration={totalDuration}
          tooltipPlacement={hitsLocation}
          tooltipContainerRef={hitTooltipContainerRef}
          onHitClick={onHitClick}
        />
      )}

      <Box bg="gray.300" borderRadius={2} boxShadow="Base" position="relative">
        <SeekBar value={seekerValue * 100} />
        <svg height="100%" width="100%" preserveAspectRatio="none">
          <svg
            viewBox="0 0 100 100"
            height="100%"
            width="100%"
            preserveAspectRatio="none"
          >
            {data.map((d, index) => {
              const { from, to } = d
              const duration = to - from

              return (
                <rect
                  key={index}
                  width={duration / scale}
                  height="100"
                  x={from / scale}
                  y="0"
                  rx="1"
                />
              )
            })}
          </svg>
        </svg>
      </Box>
      {hitsLocation === 'bottom' && (
        <HitsLine
          hits={hits}
          totalDuration={totalDuration}
          tooltipPlacement="bottom"
          tooltipContainerRef={hitTooltipContainerRef}
          onHitClick={onHitClick}
        />
      )}
    </Grid>
  )
}

export default React.memo(TalkLine)
