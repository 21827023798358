import { useSingleUser } from '@capturi/stores'
import { Flex, HStack, Text } from '@chakra-ui/react'
import UserAvatar from 'components/UserAvatar'
import UserCoachingLink from 'components/UserCoachingLink'
import React from 'react'

import { userRowHeightPx } from '../constants'

type Props = { agentUid: string }

const AgentAvatar: React.FC<Props> = ({ agentUid }) => {
  const { name, profileImage } = useSingleUser(agentUid)

  return (
    <Flex align="center" h={userRowHeightPx} alignItems="flex-end">
      <UserCoachingLink uid={agentUid}>
        <HStack align="center">
          <Text display={['none', null, null, 'block']}>
            {name.split(' ')[0]}
          </Text>
          <UserAvatar name={name} profileImage={profileImage} size="xs" />
        </HStack>
      </UserCoachingLink>
    </Flex>
  )
}

export default AgentAvatar
